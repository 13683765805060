import React from 'react';
import Loadable from 'react-loadable';
import Orders from '../containers/pages/authorised/orders/Orders';
import userRoles from '../helpers/roles';
const {dashboard,brands,products,editProduct, infoProduct, 
  merchants,orders,adminAgent,logs,reports,settings,customerSupport,
  addAgent,addMerchants,addBrands,addOrder,addProduct,orderDetails,
  viewOrder,orderInfo,invoice,addSupport, viewMerchants,
  editMerchants, editBrands, viewBrands, profile, viewAgent, 
  editAgent, viewSupport, editSupport, createOrder, 
  purchaseProduct,viewReport, customerInfo, customerInfoView, customerInfoAdd, customerInfoEdit, editMerchantRequest, merchantRequests, merchantAgreement, viewMerchantAgreement } = userRoles;
const LoadableHome = Loadable({
  loader: () => import(
    /* webpackChunkName: 'dashboard' */ '../containers/pages/authorised/dashboard/Dashboard'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableBrand = Loadable({
  loader: () => import(
    /* webpackChunkName: 'brand' */ '../containers/pages/authorised/brand/Brand'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableMerchants = Loadable({
  loader: () => import(
    /* webpackChunkName: 'merchants' */ '../containers/pages/authorised/merchants/Merchants'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableProducts = Loadable({
  loader: () => import(
    /* webpackChunkName: 'products' */ '../containers/pages/authorised/products/Products'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableMasterProduct = Loadable({
  loader: () => import(
    /* webpackChunkName: 'products' */ '../containers/pages/authorised/products/MasterProduct'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableBulkUpload = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/bulkUpload/BulkUpload'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableAddBrands = Loadable({
  loader: () => import(
    /* webpackChunkName: 'addbrand' */ '../containers/pages/authorised/brand/addBrand/AddBrand'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableEditBrands = Loadable({
  loader: () => import(
    /* webpackChunkName: 'editbrand' */ '../containers/pages/authorised/brand/editBrand/editBrand'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableViewBrands = Loadable({
  loader: () => import(
    /* webpackChunkName: 'viewbrand' */ '../containers/pages/authorised/brand/viewBrand/viewBrand'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableOrders = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/orders/Orders'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableAdminAgents = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/adminAgents/AdminAgents'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableAddAgent = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/adminAgents/addAgent/AddAgent'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableReports = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/reports/Reports'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableLogs = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/logs/Logs'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableAddMerchants = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/merchants/AddMerchant'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableViewMerchants = Loadable({
  loader: () => import(
    /* webpackChunkName: 'merchants' */ '../containers/pages/authorised/merchants/MerchantInfo'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableViewTopUpistory = Loadable({
  loader: () => import(
    /* webpackChunkName: 'merchants' */ '../containers/pages/authorised/merchants/TopUpHistory'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableEditMerchants = Loadable({
  loader: () => import(
    /* webpackChunkName: 'merchants' */ '../containers/pages/authorised/merchants/EditMerchant'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableCustomerSupport = Loadable({
  loader: () => import(
    /* webpackChunkName: 'settings' */ '../containers/pages/authorised/customerSupport/CustomerSupport'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableSettings = Loadable({
  loader: () => import(
    /* webpackChunkName: 'settings' */ '../containers/pages/authorised/settings/Settings'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableAddProduct = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/products/AddProduct'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableAddMasterProduct = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/products/AddMasterProduct'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableViewMasterProduct = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/products/ViewMasterProduct'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableEditMasterProduct = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/products/EditMasterProduct'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableProductInfo = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/products/ProductInfo'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableEditProduct = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/products/EditProduct'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableOrderDetails = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/orders/orders_details/Orders_details'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableOrderInfo = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/orders/oderInfo/oderInfo'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableAddOrder = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/orders/AddOrder'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableViewReport= Loadable({
  loader: () => import(
    /* webpackChunkName: 'view Orders' */ '../containers/pages/authorised/orders/ViewOrder'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableViewMerchantReport= Loadable({
  loader: () => import(
    /* webpackChunkName: 'view Orders' */ '../containers/pages/authorised/reports/viewReports'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableInvoice = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/invoice/Invoice'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableAddSupport = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/customerSupport/addCustomerSupport/index'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableUserProfile = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/profile/userProfile'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableViewAdmin = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/adminAgents/ViewAdminAgents'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableEditAdmin = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/adminAgents/EditAdminAgents'
  ),
  loading: () => <div>Loading...</div>
});

const LoadableViewCustomerSupport = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/customerSupport/ViewCSA'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableEditCustomerSupport = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/customerSupport/EditCSA'
  ),
  loading: () => <div>Loading...</div>
});
const LoadableCreateOrder = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/orderProduct/createOrder'
  ),
  loading: () => <div>Loading...</div>
});
const LoadablePurchaseProduct = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/orderProduct/purchaseProduct'
  ),
  loading: () => <div>Loading...</div>
});

const LoadableCustomerInfo = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/customerInfo'
  ),
  loading: () => <div>Loading...</div>
});

const LoadableCustomerInfoEdit = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/customerInfo/edit'
  ),
  loading: () => <div>Loading...</div>
});

const LoadableCustomerInfoView = Loadable({
  loader: () => import(
    /* webpackChunkName: 'orders' */ '../containers/pages/authorised/customerInfo/view'
  ),
  loading: () => <div>Loading...</div>
});

const LoadableEditMerchantRequest = Loadable({
  loader: () => import(
    /* webpackChunkName: 'merchants' */ '../containers/pages/authorised/merchants/EditMerchantRequest'
  ),
  loading: () => <div>Loading...</div>
});

const LoadableMerchantRequests = Loadable({
  loader: () => import(
    /* webpackChunkName: 'merchants' */ '../containers/pages/authorised/merchants/MerchantRequests'
  ),
  loading: () => <div>Loading...</div>
});

const LoadableMerchantAgreement = Loadable({
  loader: () => import(
    /* webpackChunkName: 'merchants' */ '../containers/pages/authorised/merchants/MerchantAgreement'
  ),
  loading: () => <div>Loading...</div>
});

const LoadableViewMerchantAgreement = Loadable({
  loader: () => import(
    /* webpackChunkName: 'merchants' */ '../containers/pages/authorised/merchants/MerchantAgreementView'
  ),
  loading: () => <div>Loading...</div>
});

export const routes = [
  {
    path: '/',
    exact: true,
    role:dashboard,
    main: (props) => <LoadableHome {...props}/>
  },
  {
    path: '/brands',
    role:brands,
    main: (props) => <LoadableBrand {...props} addAccess={addBrands}/>
  },
  {
    path: '/add-brands',
    role:addBrands,
   main: (props)=> <LoadableAddBrands {...props}/>
  },
  {
    path: '/edit-brands',
    role:editBrands,
   main: (props)=> <LoadableEditBrands {...props}/>
  },
  {
    path: '/view-brands',
    role:viewBrands,
   main: (props)=> <LoadableViewBrands {...props}/>
  },
  {
    path: '/merchants',
    role:merchants,
   main: (props)=> <LoadableMerchants {...props} addAccess={addMerchants}/>
  },
  {
    path: '/products-denomination',
    role:products,
   main: (props)=> <LoadableProducts {...props} addAccess={addProduct}/>
  },
  {
    path: '/master',
    role:products,
   main: (props)=> <LoadableMasterProduct {...props} addAccess={addProduct}/>
  },
  {
    path: '/edit-products/:id',
    role:editProduct,
   main: (props)=> <LoadableEditProduct {...props}/>
  },
  {
    path: '/view-products/:id',
    role:infoProduct,
   main: (props)=> <LoadableProductInfo {...props}/>
  },
  {
    path: '/bulkUpload',
    role:products,
   main: (props)=> <LoadableBulkUpload {...props} />
  },
  {
    path: '/orders',
    role:[1,2,3,4],
   main: (props)=> <Orders {...props} addAccess={addOrder}/>
  },
  {
    path: '/admin-agent',
    role:adminAgent,
   main: (props)=> <LoadableAdminAgents {...props} addAccess={addAgent}/>
  },
  {
    path: '/view-agent/:id',
    role: viewAgent,
   main: (props)=> <LoadableViewAdmin {...props}/>
  },
  {
    path: '/edit-agent/:id',
    role: editAgent,
   main: (props)=> <LoadableEditAdmin {...props}/>
  },
  {
    path: '/add-agent',
    role:addAgent,
   main: (props)=> <LoadableAddAgent {...props}/>
  },
  {
    path: '/reports',
    role:reports,
   main: (props)=> <LoadableReports {...props}/>
  },
  {
    path: '/logs',
    role:logs,
   main: (props)=> <LoadableLogs {...props}/>
  },
  {
    path: '/add-merchants',
    role:addMerchants,
   main: (props)=> <LoadableAddMerchants {...props}/>
  },
  {
    path: '/view-merchants/:id',
    role: viewMerchants,
   main: (props)=> <LoadableViewMerchants {...props}/>
  },
  {
    path: '/merchants-topup/:id',
    role: viewMerchants,
   main: (props)=> <LoadableViewTopUpistory {...props}/>
  },
  {
    path: '/edit-merchants/:id',
    role: editMerchants,
   main: (props)=> <LoadableEditMerchants {...props}/>
  },
  {
    path: '/orders-details',
    role:orderDetails,
   main: (props)=> <LoadableOrderDetails {...props}/>
  },
  {
    path:'/orders-info/:id',
    role:orderInfo,
    main:(props)=><LoadableOrderInfo {...props}/>

  },
  {
    path: '/view-merchant',
    role: viewReport,
   main: (props)=> <LoadableViewMerchantReport {...props}/>
  },
  {
    path: '/view-reports',
    role:viewReport,
   main: (props)=> <LoadableViewReport {...props}/>
  },
  {
    path: '/profile-settings',
    role:settings,
   main: (props)=> <LoadableSettings {...props}/>
  },
  {
    path: '/customer-support',
    role:customerSupport,
   main: (props)=> <LoadableCustomerSupport {...props} addAccess={addSupport}/>
  },
  {
    path: '/view-support/:id',
    role: viewSupport,
   main: (props)=> <LoadableViewCustomerSupport {...props}/>
  },
  {
    path: '/edit-support/:id',
    role: editSupport,
   main: (props)=> <LoadableEditCustomerSupport {...props}/>
  },
  {
    path: '/add-support',
    role:addSupport,
   main: (props)=> <LoadableAddSupport {...props}/>
  },
  {
    path: '/add-products',
    role:addProduct,
   main: (props)=> <LoadableAddProduct {...props}/>
  },
  {
    path: '/add-master',
    role:addProduct,
   main: (props)=> <LoadableAddMasterProduct {...props}/>
  },
  {
    path: '/view-master',
    role:addProduct,
   main: (props)=> <LoadableViewMasterProduct {...props}/>
  },
  {
    path: '/edit-master',
    role:addProduct,
   main: (props)=> <LoadableEditMasterProduct {...props}/>
  },
  {
    path: '/add-orders',
    role:addOrder,
   main: (props)=> <LoadableAddOrder {...props}/>
  },
 
  {
    path: '/invoice',
    role:invoice,
   main: (props)=> <LoadableInvoice {...props}/>
  },
  {
    path: '/profile',
    role: profile,
   main: (props)=> <LoadableUserProfile {...props}/>
  },
  {
    path: '/create-order',
    role: createOrder,
   main: (props)=> <LoadableCreateOrder {...props}/>
  },
  {
    path: '/purchased-product',
    role: purchaseProduct,
   main: (props)=> <LoadablePurchaseProduct {...props}/>
  },
  
  {
    path: '/customer-info',
    role: customerInfo,
   main: (props)=> <LoadableCustomerInfo {...props} addAccess={ customerInfo } />
  },
  {
    path: '/customer-info-edit/:id',
    role: customerInfoEdit,
   main: (props)=> <LoadableCustomerInfoEdit {...props} />
  },
  {
    path: '/customer-info-view/:id',
    role: customerInfoView,
    main: (props)=> <LoadableCustomerInfoView {...props} />
  },
  {
    path: '/merchant-request/:id',
    role: editMerchantRequest,
   main: (props)=> <LoadableEditMerchantRequest {...props}/>
  },
  {
    path: '/merchant-requests',
    role:merchantRequests,
   main: (props)=> <LoadableMerchantRequests {...props} addAccess={merchantRequests}/>
  },
  {
    path: '/merchant-agreement/:id',
    role: merchantAgreement,
   main: (props)=> <LoadableMerchantAgreement {...props}/>
  },
  {
    path: '/view-merchant-agreement',
    role: viewMerchantAgreement,
   main: (props)=> <LoadableViewMerchantAgreement {...props}/>
  },
];