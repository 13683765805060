import React, { useContext, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { imagePath } from "../../../utils/assetUtils";
import "./Sidebar.scss";
const Logo = imagePath("Nodwin-Gaming-transparent.png");
import userRoles from "../../../helpers/roles";
//contxt
import AppContext from "../../../state/context";

export default function Sidebar() {
  const {
    state: { role },
    handleTransactionDropdown,
    handleSettingDropdown,
    drpDwnKeepOpen,
    closeSidebar,
  } = useContext(AppContext);
  const {
    dashboard,
    brands,
    products,
    masterProduct,
    productDenomination,
    bulkUpload,
    merchants,
    orders,
    adminAgent,
    logs,
    reports,
    settings,
    customerSupport,
    orderProduct,
    createOrder,
    purchaseProduct,
    pullList,

    customerInfo,
    merchantRequests
  } = userRoles;
  const { location } = useHistory();
  const brandClass = location.pathname.match(/brands/gi) ? "active" : "";
  const merchantClass = location.pathname.match(/merchants/gi) ? "active" : "";
  const productClass = location.pathname.match(/products/gi) ? "active" : "";
  const masterClass = location.pathname.match(/master/gi) ? "active" : "";
  const bulkUploadClass = location.pathname.match(/bulkUpload/gi)
    ? "active"
    : "";
  const orderClass = location.pathname.match(/orders/gi) ? "active" : "";
  const agentClass = location.pathname.match(/agent/gi) ? "active" : "";
  const customerClass = location.pathname.match(/support/gi) ? "active" : "";

  const profileClass = location.pathname.match(/profile/gi) ? "active" : "";
  const reportClass = location.pathname.match(/report/gi) ? "active" : "";

  const customeInfoClass = location.pathname.match(/info/gi) ? "active" : "";

  const merchantRequestClass = location.pathname.match(/merchant-request/gi) ? "active" : "";

  useEffect(() => {
    handleTransactionDropdown("#transactionOrder");
    handleSettingDropdown("#settingDrp");
    drpDwnKeepOpen();
    closeSidebar();
  }, []);

  return (
    <>
      <ul className="sidebar navbar-nav">
        <li className="nav-item text-center">
          <NavLink className="navbar-brand mr-1" exact to="/">
            <img src={Logo} alt="Logo" />
          </NavLink>
        </li>
        <li className="nav-item ">
          <div className="menu-text">MENU</div>
          {/* className="menu-text" */}
        </li>
        {dashboard.indexOf(role) >= 0 && (
          <li className="nav-item">
            <NavLink className="nav-link" exact to="/">
              <i className="bx bx-home-circle mr-3"></i>
              <span>Dashboard</span>
            </NavLink>
          </li>
        )}
        {brands.indexOf(role) >= 0 && (
          <li className={`${brandClass} nav-item`}>
            <NavLink className="nav-link" to="/brands">
              <i className="bx bx-shape-polygon mr-3"></i>
              <span>Brands</span>
            </NavLink>
          </li>
        )}

        {products.indexOf(role) >= 0 && (
          <li className="nav-item">
            <div className="dropdown" id="settingDrp" style={{ width: "" }}>
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i className="bx bxs-package mr-3"></i>
                <span>Products</span>
                <i className="bx bxs-chevron-down ml-3"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {masterProduct.indexOf(role) >= 0 && (
                  <li className={`${masterClass} nav-item`}>
                    <NavLink className="nav-link" to="/master" index="1">
                      <i className="bx bx-layer mr-3"></i>
                      <span>Master Product</span>
                    </NavLink>
                  </li>
                )}
                {productDenomination.indexOf(role) >= 0 && (
                  <li className={`${productClass} nav-item`}>
                    <NavLink className="nav-link" to="/products-denomination">
                      <i className="bx bxs-dollar-circle mr-3"></i>
                      <span>Product Variance</span>
                    </NavLink>
                  </li>
                )}
              </div>
            </div>
          </li>
        )}

        {bulkUpload.indexOf(role) >= 0 && (
          <li className={`${bulkUploadClass} nav-item`}>
            <NavLink className="nav-link" to="/bulkUpload">
              <i className="bx bx-upload mr-3"></i>
              <span>Bulk Upload</span>
            </NavLink>
          </li>
        )}
        {orderProduct.indexOf(role) >= 0 && (
          <li className="nav-item">
            <div
              className="dropdown"
              id="transactionOrder"
              style={{ width: "" }}
            >
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-detail mr-3"></i>
                <span>Product Pull Request</span>
                {/* <span>Transaction Order</span> */}
                {/* <span className="change"> */}
                <i className="bx bxs-chevron-down ml-3"></i>
                {/* </span> */}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {createOrder.indexOf(role) >= 0 && (
                  <NavLink className="nav-link" to="/create-order">
                    <i className="bx bxs-cart-add mr-3"></i>
                    <span>Create Pull Request</span>
                    {/* <span>Create Transaction</span> */}
                  </NavLink>
                )}
                {purchaseProduct.indexOf(role) >= 0 && (
                  <NavLink className="nav-link" to="/purchased-product">
                    <i className="bx bx-purchase-tag mr-3"></i>
                    <span>Pull Request List</span>
                  </NavLink>
                )}
              </div>
            </div>
          </li>
        )}

        {pullList.indexOf(role) >= 0 && (
          <li className="nav-link" style={{ padding: "0 0.8rem" }}>
            <NavLink className="nav-link" to="/purchased-product">
              <i className="bx bx-purchase-tag mr-3"></i>
              <span>Pull Request List</span>
            </NavLink>
          </li>
        )}

        {merchants.indexOf(role) >= 0 && (
          <li className={`${merchantClass} nav-item`}>
            <NavLink className="nav-link" to="/merchants">
              <i className="bx bx-sitemap mr-3"></i>
              <span>Merchants</span>
            </NavLink>
          </li>
        )}

          {merchantRequests.indexOf(role) >= 0 && (
          <li className={`${merchantRequestClass} nav-item`}>
            <NavLink className="nav-link" to="/merchant-requests">
              <i className="bx bx-sitemap mr-3"></i>
              <span>Merchant Requests</span>
            </NavLink>
          </li>
        )}

        {role === 3 ?

          orders.indexOf(role) >= 0 && (
            <li className="nav-item">
              <div className="dropdown" id="settingDrp" style={{ width: "" }}>
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <i className="bx bx-file mr-3"></i>
                  <span>Orders</span>
                  <i className="bx bxs-chevron-down ml-3"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >

                  <NavLink className="nav-link" to="/orders">
                  <i className='bx bx-list-ol mr-3' ></i>
                    <span>Orders List</span>
                  </NavLink>

                  <NavLink className="nav-link" to="/add-orders">
                  <i className='bx bxs-cart-add mr-3' ></i>
                    <span>Place Order</span>
                  </NavLink>
                </div>
              </div>
            </li>
          )

          :

          orders.indexOf(role) >= 0 && (
            <li className={`${orderClass} nav-item`}>
              <NavLink className="nav-link" to="/orders">
                <i className="bx bx-file mr-3"></i>
                <span>Orders</span>
              </NavLink>
            </li>
          )
        }

        {reports.indexOf(role) >= 0 && (
          <li className={`${reportClass} nav-item`}>
            <NavLink className="nav-link" to="/reports">
              <i className="bx bxs-bar-chart-alt-2 mr-3"></i>
              <span>Reports</span>
            </NavLink>
          </li>
        )}
        {settings.indexOf(role) >= 0 && (
          <li className="nav-item">
            <div className="dropdown" id="settingDrp" style={{ width: "" }}>
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i className="bx bx-cog mr-3"></i>
                <span>Settings</span>
                <i className="bx bxs-chevron-down ml-3"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <NavLink
                  className={`${profileClass} nav-link`}
                  to="/profile"
                  index="0"
                >
                  <i className="bx bx-user mr-3"></i>
                  <span>Profile</span>
                </NavLink>
                {customerSupport.indexOf(role) >= 0 && (
                  <li className={`${customerClass} nav-item`}>
                    <NavLink
                      className="nav-link"
                      to="/customer-support"
                      index="1"
                    >
                      <i className="bx bx-support  mr-3"></i>
                      <span>Customer Support</span>
                    </NavLink>
                  </li>
                )}
                {adminAgent.indexOf(role) >= 0 && (
                  <li className={`${agentClass} nav-item`}>
                    <NavLink className="nav-link" to="/admin-agent">
                      <i className="bx bxs-user mr-3"></i>
                      <span>Admin Agents</span>
                    </NavLink>
                  </li>
                )}
                {logs.indexOf(role) >= 0 && (
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/logs">
                      <i className="bx bx-search mr-3"></i>
                      <span>Logs</span>
                    </NavLink>
                  </li>
                )}
              </div>
            </div>
          </li>
        )}
        {customerInfo.indexOf(role) >= 0 && (
        <li className={`${customeInfoClass} nav-item`}>
            <NavLink className="nav-link" to="/customer-info">
            <i className="bx bxs-bar-chart-alt-2 mr-3"></i>
            <span>Customer Info</span>
            </NavLink>
        </li>
        )}
      </ul>
    </>
  );
}
