import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import OrderDataTable from "../../../../components/Datatable/OrderDataTable";
import { toaster } from "../../../../components/toaster";
import AppContext from "../../../../state/context";
import AddButton from "../../../../components/Buttons/addButton";
import { api } from "../../../../api";
import { useQuery } from "../../../../components/hooks/useQuery";
import { CSVLink } from "react-csv";
// import CsvDownloader from 'react-csv-downloader';
// import CsvLink from "react-csv-export";

export default function Orders(props) {
  const today = new Date().toLocaleDateString("en-ca");
  const ref = React.useRef(null);
  const { addAccess } = props;
  const {
    state: { role },
  } = useContext(AppContext);
  const query = useQuery();
  let currentDateTime = new Date();
  currentDateTime = currentDateTime.getTime();
  const ordrStartDt = query.get("startDate");
  const ordrEndDt = query.get("endDate");
  // const [startDate, setStartDate] = useState(ordrStartDt ? ordrStartDt : today);
  // const [endDate, setEndDate] = useState(ordrEndDt ? ordrEndDt : today);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cartData, setCartData] = useState("");
  const [columns, setColumns] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [reportLoad, setReportLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedReports, setupdatedReports] = useState({});
  const [dataCsv, setDataCsv] = useState([]);
  const [downloadData, setDownload] = useState([]);
  const history = useHistory();
  //
  const [allData, setAllData] = useState(false);
  //

  // let dataTableRef = React.createRef();
  const handleCart = () => {
    if (cartData.length > 0) {
      history.push("/orders-details");
    }
    {
      if (role === 3) {
        api.orders.getCarts().then((result) => {
          if (result.success == true) {
            setCartData(result.data);
          } else {
            toaster(result.message, "error");
          }
        });
      }
    }
  };

  useEffect(() => {
    downloadClick(false);
    if (role === 3) {
      api.orders.getCarts().then((result) => {
        if (result.success == true) {
          setCartData(result.data);
        } else {
          // toaster(result.message, 'error')
        }
      });
    }
    return () => {
      localStorage.removeItem("dateChanged");
    };
  }, []);

  function handleDetail(row) {
    history.push(`/orders-details/${row}`);
  }

  function viewOrder(e, row) {
    e.stopImmediatePropagation();
    history.push(`/orders-info/${row}`);
  }

  const handleClick = () => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (startDateObj <= endDateObj) {
      setIsLoading(true);
      setAllData(true);
      downloadClick(true);
      const formBody = {
        startDate: startDate,
        endDate: endDate,
        pageSize: "10",
        pageNo: "1",
      };

      api.orders
        .getOrdersData(formBody)
        .then((res) => {
          setColumns(res.data);
          setupdatedReports(res.data);
          setUpdateData(true);
          setReportLoad(true);
        })
        .catch((err) => console.log("error", err))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      toaster("Start date must be smaller than end date", "error");
    }
  };

  let dataColumns = [
    {
      data: "orderId",
      title: "Id",
      visible: true,
      render: function(data, type, row, meta) {
        return row
          ? `<input type='hidden' value=${row.orderId}/><span>${row.orderId}</span>`
          : null;
      },
    },
    {
      data: "orderDate",
      title: "Order Date",
    },
    {
      data: "merchentName",
      title: "Merchant Name",
      render: function(data, type, row) {
        // var copyResult = data?.replace(/[ ]/g,"\u00a0")
        return row.merchentName
          ? `<span class="add-ellip"> ${data} </span>`
          : "";
      },
    },
    // {
    //   data: "orderEmailId",
    //   title: "Email",
    // },
    {
      data: "orderNo",
      title: "Order ID",
    },
    {
      data: "orderEmailId",
      title: "Order Email ID",
    },
    {
      data: "ordersItems",
      title: "Products",
      render: function(data, type, row) {
        // var copyResult = data?.replace(/[ ]/g,"\u00a0")
        return row.ordersItems
          ? `<span class="add-ellip"> ${data} </span>`
          : "";
      },
    },
    {
      data: "paymentZone",
      title: "State",
    },
    {
      data: "ordersQuantity",
      title: "Total Quantity",
    },
    // {
    //   data: "orderAmount",
    //   title: "Amount",
    //   render: function(data, type, row, meta) {
    //     return row ? `<span> ${row.orderAmount.toFixed(2)}</span>` : null;
    //   },
    // },
    // {
    //   data: "balanceCredit",
    //   title: "Available Amount",
    //   render: function(data, type, row, meta) {
    //     return row ? `<span> ${row.balanceCredit.toFixed(2)}</span>` : null;
    //   },
    // },
    {
      data: "orderAmount",
      title: "Total Amount",
      render: function(data, type, row, meta) {
        let discounts = row.savingAmount === null ? 0 : row.savingAmount;
        return row && row.orderAmount
          ? `<span> ${row.orderAmount.toFixed(2)}</span>`
          : null;
      },
    },
    {
      data: "balanceCredit",
      title: "Discount Amount",
      render: function(data, type, row, meta) {
        return row && row.savingAmount
          ? `<span> ${row.savingAmount.toFixed(2)}</span>`
          : null;
      },
    },
    {
      data: "comment",
      title: "Comment",
    },
    {
      data: "status",
      title: "Status",
      sortable: false,
      render: function(data, type, row, meta) {
        let orderClass = "";
        if (data == "Completed") {
          orderClass = "Cann";
        }
        if (data == "Cancelled") {
          orderClass = "Inactive";
        }
        return '<a class="' + orderClass + '"><span>' + data + "</span></a>";
      },
    },
    {
      data: "Action",
      title: "Action",
      sortable: false,
      render: function(data, type, row) {
        return row
          ? `<a class="view-btn viewOrder" data-row="${
              row.orderId
            }"><p class="ViewButton">View</p></a>${
              addAccess.indexOf(role) >= 0 ? `` : ""
            }`
          : null;
      },
    },
  ];

  const csvDataHandler = (values) => {
    const csvHeaders = [
      "Id",
      "OrderDate",
      "Merchant Name",
      "Order ID",
      "Order Email ID",
      "Products",
      "State",
      "Total Quantity",
      "Total Amount",
      "Discount Amount",
      "Comment",
      "Status",
    ];
    const csvArray = [];
    csvArray.push(csvHeaders);
    for (let i = 0; i < values.length; i++) {
      const dateObj = new Date(values[i]?.orderDate);
      const formattedDate = `${dateObj
        .getDate()
        .toString()
        .padStart(2, "0")}-${(dateObj.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dateObj.getFullYear()}`;

      let discounts =
        values[i]?.savingAmount === null ? 0 : values[i]?.savingAmount;
      let currentArray = [
        `${values[i]?.orderId}` || "NA",
        `${formattedDate}` || "NA",
        `${values[i]?.merchentName}` || "NA",
        `${values[i]?.orderNo}` || "NA",
        `${values[i]?.orderEmailId}` || "NA",
        `${values[i]?.ordersItems}` || "NA",
        `${values[i]?.paymentZone}` || "NA",
        `${values[i]?.ordersQuantity}` || "NA",
        `${values[i]?.orderAmount}` || "NA",
        `${values[i]?.savingAmount}` || "NA",
        `${values[i]?.comment}` || "NA",
        `${values[i]?.status}` || "NA",
      ];
      csvArray.push(currentArray);
    }
    setDataCsv(csvArray);
  };

  const downloadClick = (isAll) => {
    setIsLoading(true);
    const formBody = {
      startDate: isAll ? startDate : "",
      endDate: endDate,
    };

    api.orders
      .exportAllOrders(formBody)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          csvDataHandler(res.data);
        } else {
          csvDataHandler([]);
        }
      })
      .catch((err) => console.log("error", err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="box-row">
            <div className="table-head">
              <h1 className="sec-header">
                ORDERS
                <AddButton
                  link="/add-orders"
                  buttonName="Place Order"
                  appRole={addAccess}
                />
                {role === 3 && (
                  <span className="view-btn" onClick={handleCart}>
                    <i
                      className="bx bx-cart float-right bold dropdown-toggle p-2"
                      style={{ position: "relative", marginRight: "30px" }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "-15px",
                          left: "18px",
                          background: "#5C2E91",
                          padding: "5px",
                          borderRadius: "50%",
                          lineHeight: "8px",
                          color: "#fff",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                        }}
                      >
                        {cartData.length}
                      </span>
                    </i>
                  </span>
                )}
              </h1>
              <div className="row">
                <div className="form-group col-md-3">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={startDate}
                    // value={ordrStartDt ? startDate : today}
                    value={startDate}
                    max={today}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      history.replace(`/orders?startDate=${e.target.value}`);
                    }}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="endDate">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    max={today}
                    // value={ordrEndDt ? endDate : today}
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      history.replace(
                        `/orders?startDate=${startDate}&endDate=${e.target.value}`
                      );
                    }}
                  />
                </div>
                <div className="form-group col-md-3" style={{ top: "11px" }}>
                  <button
                    type="button"
                    style={{ width: "100%" }}
                    onClick={handleClick}
                    className="btn mt-3 submit-btn"
                    htmlFor="gridCheck"
                    disabled={isLoading}
                  >
                    Get Orders
                  </button>
                </div>
                <div className="form-group col-md-3" style={{ top: "11px" }}>
                  {dataCsv.length > 0 ? (
                    <CSVLink
                      data={dataCsv}
                      filename={"OrderDataReport_" + currentDateTime + ".csv"}
                    >
                      <button
                        // onClick={downloadClick}
                        type="button"
                        className="btn mt-3 submit-btn"
                        style={{ width: "75%" }}
                        disabled={isLoading}
                      >
                        Download
                      </button>
                    </CSVLink>
                  ) : (
                    <button
                      type="button"
                      className="btn mt-3 submit-btn"
                      style={{ width: "75%" }}
                      disabled={isLoading}
                    >
                      Download
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="card card-table">
              {/* <div className="card-header card-header-title">Order Details</div> */}

              <div className="card-body gatepass-saveoutput">
                <div className="reports">
                  <OrderDataTable
                    dataref={ref}
                    columns={dataColumns}
                    colum={columns}
                    // dataCsv={dataCsv}
                    // setDataCsv={setDataCsv}
                    page={"Orders"}
                    dataUrl={"/api/orders"}
                    viewOrder={viewOrder}
                    handleDetail={handleDetail}
                    handleClick={handleClick}
                    startDate={allData ? startDate : ""}
                    endDate={endDate || ""}
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                    updatedReports={updatedReports && updatedReports.result}
                    reportLoad={reportLoad}
                    setReportLoad={setReportLoad}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
